.why-main-container {
  height: 50vh;
  width: 100%;
  display: flex;
  background: #26395e;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding-left: 30px; */
  gap: 20px;
}

.why-title {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-1);
  color: #fff;
}

.why-content {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 400;
  font-size: var(--font-size-3);
  color: #fff;
  text-align: center;
}

span {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 700;
  font-size: var(--font-size-2);
  color: #e64848;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-device-width: 760px) {
  .why-content{
    width: 80%;
  }
}